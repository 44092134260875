
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

// import our local modules
@import '../modules/local/typography/_index';


//==========  Begin styles  ==========//

// Helper class
.hidden {
	display: none;
}


// Reset
.cms-toolbar {
	@extend .hidden;
}


.mobile-ui {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding-bottom: 0 !important;
	padding: 2rem;
}

.header {

	.menu-toggle,
	.nav {
		@extend .hidden;
	}

	.inner {

		* { 
			@extend .hidden; 
		}

		.company-logo-link,
		.company-logo {
			display: block;
		}

		.company-logo {
			margin-bottom: 3rem;
		}
	}
}

.hero {

	.inner {
		
		* { 
			@extend .hidden; 
		}
		
		.profile-image-wrapper,
		.profile-image {
			display: block;
		}

		.profile-image-wrapper {
			margin-bottom: 3rem;
		}
	}
}

.block-meet-the-team, .footer-nav, .footer-offices, .footer-logos {
 	@extend .hidden;
}

.column {

	&.right,
	&.left {
		@extend .hidden;
	}
}

.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.social-icons,
.footer-links {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}


.section-profile {
	.column {
		&.right {
			display: block;

			> * {
				@extend .hidden;
			}

			.staff-card-container, .profile-image {
				display: block;

				.staff-details, .officeDirections {
					@extend .hidden;
				}

				.staff-contact {
					ul {
						li {
							@extend .hidden;
						}
					}

					.phone {
						display: block;

						&:before {
							content: 'T: '
						}
					}
				}

				.staff-office {
					.phone {
						&:before {
							content: 'T: '
						}
					}

					.fax {
						&:before {
							content: 'F: '
						}
					}
				}
			}

			.staff-attributes {
				> li {
					margin-bottom: $paragraph-margin / 2;

					.h3 {
						margin-bottom:  $paragraph-margin / 3;
					}
				}
			}
		}
	}
}
